import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
		<div {...props} className={classes}>
			<h1 className='m-0'>
				<Link to='/'>
					<Image
						src={require('./../../../assets/images/logofooter.png')}
						alt='Open'
						width={64}
						height={64}
					/>
				</Link>
			</h1>
			Troovr Ltd, 13043826, 49 Greek Street, London, W1D 4EG
		</div>
	);
}

export default Logo;