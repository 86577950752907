import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'A full-detail data analysis',
    paragraph: 'Troovr is about data, networks and user behaviour - organisation, classification, analysis , inferences - so you can have the Web in your way, but never sacrificing truth. '
  };

  return (
		<section {...props} className={outerClasses}>
			<div className='container'>
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className='center-content' />
					<div className={splitClasses}>
						<div className='split-item'>
							<div
								className='split-item-content center-content-mobile reveal-from-left'
								data-reveal-container='.split-item'
							>
								<div className='text-xxs text-color-primary fw-600 tt-u mb-8'>Media Literacy</div>
								<h3 className='mt-0 mb-12'>A Digital Magnifying Glass</h3>
								<p className='m-0'>
									The system not only gives you tools to better check the contents you interact
									with: it <strong>teaches</strong> you how to recognize the signals that
									disinformation leaves behind. You will never see content in the same way again.
								</p>
							</div>
							<div
								className={classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill',
								)}
								data-reveal-container='.split-item'
							>
								<Image
									src={require('./../../assets/images/features-split-image-01.png')}
									alt='Features split 01'
									width={528}
									height={396}
								/>
							</div>
						</div>

						<div className='split-item'>
							<div
								className='split-item-content center-content-mobile reveal-from-right'
								data-reveal-container='.split-item'
							>
								<div className='text-xxs text-color-primary fw-600 tt-u mb-8'>Data Ownership</div>
								<h3 className='mt-0 mb-12'>Take Back Control of Your Data </h3>
								<p className='m-0'>
									You decide what part of your data you connect to Troovr, and it will never be
									sold. The more data you provide, the better your analysis becomes. In one place
									you can visualize the data you produce everywhere on the web, all anonymized and
									untraceable. Troovr is the <strong>digital safe</strong> where you store your
									data.
								</p>
							</div>
							<div
								className={classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill',
								)}
								data-reveal-container='.split-item'
							>
								<Image
									src={require('./../../assets/images/features-split-image-02.png')}
									alt='Features split 02'
									width={528}
									height={396}
								/>
							</div>
						</div>

						<div className='split-item'>
							<div
								className='split-item-content center-content-mobile reveal-from-left'
								data-reveal-container='.split-item'
							>
								<div className='text-xxs text-color-primary fw-600 tt-u mb-8'> Digital Mapping</div>
								<h3 className='mt-0 mb-12'>A New Navigation Experience</h3>
								<p className='m-0'>
									Troovr allows users to redesign the map of the digital environment and get back
									the internet without the filter of shady algorithms. See the{' '}
									<strong>real colours</strong> of the Web - and help the others to do the same.
								</p>
							</div>
							<div
								className={classNames(
									'split-item-image center-content-mobile reveal-from-bottom',
									imageFill && 'split-item-image-fill',
								)}
								data-reveal-container='.split-item'
							>
								<Image
									src={require('./../../assets/images/features-split-image-03.png')}
									alt='Features split 03'
									width={528}
									height={396}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;