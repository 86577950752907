import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
// import Input from '../elements/Input';
// import MailchimpSubscribe from 'react-mailchimp-subscribe';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const CtaPlus = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
		<section {...props} className={outerClasses}>
			<div className='container'>
				<div className={innerClasses}>
					<h3 className='m-0'>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://airtable.com/shrmo28j8d6GiuTQd'
						>
							<div className='text-cta'>
								Click here to leave your info and stay tuned with Troovr.
							</div>
						</a>{' '}
						We'll keep you updated!
					</h3>
				</div>
			</div>
		</section>
	);
}

CtaPlus.propTypes = propTypes;
CtaPlus.defaultProps = defaultProps;

export default CtaPlus;