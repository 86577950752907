import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
		title: 'Burst your own Bubble',
		paragraph:
			'Troovr shields you from the digital bubble that the tech platforms built to make you generate more revenue for them. From day one, you stay in touch with what you like the most but never miss what people with different opinions are into.',
	};

  return (
		<section {...props} className={outerClasses}>
			<div className='container'>
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className='center-content' />
					<div className={tilesClasses}>
						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('./../../assets/images/feature-tile-icon-01.svg')}
											alt='Features tile icon 01'
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>How Troovr works?</h4>
									<p className='m-0 text-sm'>
										Troovr is a context manager working behind each content you interact with, so
										you have more resources to make well-informed decisions, becoming a trust agent
										in your network.
									</p>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom' data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('./../../assets/images/feature-tile-icon-02.svg')}
											alt='Features tile icon 02'
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Privacy Rules - Always</h4>
									<p className='m-0 text-sm'>
										Who is tracking you with cookies? Who holds data about you? How much exposed you
										are? For us, Privacy is not only a priority: it's a foundation of the whole
										project.
									</p>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom' data-reveal-delay='400'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('./../../assets/images/feature-tile-icon-03.svg')}
											alt='Features tile icon 03'
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Your data, your rules</h4>
									<p className='m-0 text-sm'>
										You decide what you want to feed the system, and then you can change your mind
										at any time. No sales for the highest bidder, no nothing. You own your data,{' '}
										<strong>forever</strong>.
									</p>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('./../../assets/images/feature-tile-icon-04.svg')}
											alt='Features tile icon 04'
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>User-Centric Approach</h4>
									<p className='m-0 text-sm'>
										Users are the engine that drives the system. Troovr is a community of people
										using their data to help each other make the online content landscape more
										transparent, while <strong>never</strong> compromising on privacy and identity
										protection.
									</p>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom' data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('./../../assets/images/feature-tile-icon-05.svg')}
											alt='Features tile icon 05'
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>Trust as a currency</h4>
									<p className='m-0 text-sm'>
										When you connect your data to your Troovr account, it's like a bank storing your
										money. User <strong>trust</strong> is what put the wheels in motion and don't
										take our word for it: we'll go through audits to show how serious we are.
									</p>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom' data-reveal-delay='400'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='features-tiles-item-image mb-16'>
										<Image
											src={require('./../../assets/images/feature-tile-icon-06.svg')}
											alt='Features tile icon 06'
											width={64}
											height={64}
										/>
									</div>
								</div>
								<div className='features-tiles-item-content'>
									<h4 className='mt-0 mb-8'>In doubt? Check it out!</h4>
									<p className='m-0 text-sm'>
										If you are uncertain about a piece of information, The system provides tools for
										you to check whether that content is solid or not, with very little effort and{' '}
										<strong>100% transparency</strong>.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;